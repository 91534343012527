<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-show="!edition && !$route.params.mvm">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'facts_list'"
          :AllResponse="'allfact'"
          :title="title"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'factures'"
          :sheetname="'factures'"
          @open="NewFactForm"
          @rowselect="FactChange"
          :showedit="false"
          :key="klist"
          :TypeScope="fact_type"
          :Add="editer"
          :del="false"
          :search_elm="filtre"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="6" md="8">
        <factprint
          :key="kf"
          :fact="facture"
          :fact_type="fact_type"
          :mvms_list="mvms_list"
          @change="FactChange"
          :editer="editer"
          :valider="valider"
          :solder="solder"
          :facturer="facturer"
          :modes="modes"
          :comptes="comptes"
          @refresh="refresh"
          :edition="edition"
          @edition="edit_change"
          @options="get_option"
          :data_loading="data_load"
          :ipg="options.itemsPerPage"
          :last_facture="last_facture"
          :exercicesperiodes="exercicesperiodes"
        >
        </factprint>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import FACT from "../graphql/Facture/FACT.gql";
import FACTS from "../graphql/Facture/FACTS.gql";
import ALLFACT from "../graphql/Facture/ALLFACT.gql";
import ACTUAL_MVMS from "../graphql/Facture/ACTUAL_MVMS.gql";
//import ACTUAL_CMDS from "../graphql/Facture/ACTUAL_CMDS.gql";
import DATA from "../graphql/Facture/DATA.gql";
import UPDATE_FACT from "../graphql/Facture/UPDATE_FACT.gql";
import LAST_FACTURE from "../graphql/Facture/LAST_FACTURE.gql";
import EXERCICE_PERIODE from "../graphql/Compta/EXERCICE_PERIODE.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    factprint: () => import("../components/FactPrint.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    fact_type: Number,
  },
  data: () => ({
    filtre: null,
    klist: 0,
    edition: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editer: false,
    solder: false,
    valider: false,
    facturer: false,
    selitem: {},
    options: {},
    kf: 100,
    data_load: false,
    headers: [
      {
        text: "Code",
        value: "nofact",
        enum: "NOFACT",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },

      {
        text: "Date",
        value: "fact_date",
        enum: "FACT_DATE",
        slot: "date",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
        sm: 8,
        md: 8,
      },

      {
        text: "Montant HT",
        value: "mont_ht",
        enum: "MONT_HT",
        align: "end",
        slot: "cur",
        selected: false,
        sm: 8,
        md: 8,
      },
      {
        text: "Montant TVA",
        value: "mont_tva",
        enum: "MONT_TVA",
        align: "end",
        slot: "cur",
        selected: false,
        sm: 8,
        md: 8,
      },

      {
        text: "Montant TTC",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        selected: false,
        sm: 8,
        md: 8,
      },

      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
        sm: 4,
        md: 4,
      },
    ],

    facture: {},
    changed: false,
    mvms_list: [],
    loading: false,
    modes: [],
    modalites: [],
    comptes: [],
    last_facture: {},
    exercicesperiodes: [],
  }),

  computed: {
    title() {
      let t;
      if (this.fact_type == 3) t = "Factures Clients";
      if (this.fact_type == 4) t = "Factures Avoir Clients";
      if (this.fact_type == 1) t = "Factures Fournisseurs";
      if (this.fact_type == 2) t = "Factures Avoir Fournisseurs";
      return t;
    },
    Qselect() {
      return FACTS;
    },
    Qselectall() {
      return ALLFACT;
    },
  },
  watch: {
    fact_type: {
      handler() {
        this.facture = {};
        this.$store.dispatch("Changed", true);
        this.kf++;
        this.get_data();
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  created() {},
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.isEditing) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Quitter sans enregistrement !! Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        await this.update_modif();
        next();
      } else return;
    } else next();
  },
  async mounted() {
    this.headers[2].text =
      this.fact_type == 3 || this.fact_type == 4 ? "Client" : "Fournisseur";

    this.editer =
      (this.fact_type == 1 && this.$store.state.auth.includes("02014")) ||
      (this.fact_type == 2 && this.$store.state.auth.includes("02015")) ||
      (this.fact_type == 3 && this.$store.state.auth.includes("02012")) ||
      (this.fact_type == 4 && this.$store.state.auth.includes("02013")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.fact_type == 1 && this.$store.state.auth.includes("03014")) ||
      (this.fact_type == 2 && this.$store.state.auth.includes("03015")) ||
      (this.fact_type == 3 && this.$store.state.auth.includes("03012")) ||
      (this.fact_type == 4 && this.$store.state.auth.includes("03013")) ||
      this.$store.state.isadmin;

    this.solder =
      (this.fact_type == 1 && this.$store.state.auth.includes("04014")) ||
      (this.fact_type == 2 && this.$store.state.auth.includes("04015")) ||
      (this.fact_type == 3 && this.$store.state.auth.includes("04012")) ||
      (this.fact_type == 4 && this.$store.state.auth.includes("04013")) ||
      this.$store.state.isadmin;

    this.facturer =
      (this.fact_type == 3 && this.$store.state.auth.includes("01067")) ||
      this.$store.state.isadmin;
    await this.get_data();
    //from payement
    if (this.$route.params.paye) {
      this.selitem = this.$route.params.paye;
      this.mvme = this.selitem;
      this.keymvm++;
      this.klist++;
    }
    //from mouvments
    if (this.$route.params.mvm) {
      let i = this.mvms_list.findIndex(
        (elm) => elm.id == this.$route.params.mvm.id
      );
      if (i >= 0) {
        this.facture.mvm_id = this.$route.params.mvm.id;
        this.facture.tier_id = this.mvms_list[i].tier_id;
        this.facture.country_id = this.mvms_list[i].country_id;
        this.facture.RC = this.mvms_list[i].RC;
        this.facture.NIF = this.mvms_list[i].NIF;
        this.facture.ART = this.mvms_list[i].ART;
        this.facture.code_cmpt = this.mvms_list[i].code_cmpt;
        this.facture.modepaiement = this.mvms_list[i].modepaiement;
        this.facture.delai_paye = this.mvms_list[i].delai_paye
          ? this.mvms_list[i].delai_paye
          : this.mvms_list[i].tier_delai;
      }

      this.facture.id = -1;
      this.edition = true;
      this.kf++;
    }
    if (this.$route.params.facture) {
      this.filtre = this.$route.params.facture.nofact;
      this.selitem = this.$route.params.facture;
      this.facture = this.selitem;
      this.kprint++;
      this.klist++;
    }
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  methods: {
    get_option(item) {
      this.options = item;
    },
    async preventNav(event) {
      if (!this.$store.state.isEditing) return;
      event.preventDefault();
      event.stopImmediatePropagation();

      //event.returnValue = "";
      await this.update_modif();
      //
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.facture.id > 0) {
        let v = {
          fact: {
            id: this.facture.id,
            modif_by: null,
            modif_date: null,
          },
        };

        await this.maj(UPDATE_FACT, v);
      }
    },

    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    edit_change(value) {
      this.edition = value;
    },
    async requette(query, v, f) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async refresh() {
      let v = {
        where: {
          AND: [
            { column: "STATUT_ID", value: 11 },
            { column: "TTC_RESTE", operator: "GT", value: 0 },
            { column: "REPLACE", value: 0 },
            { column: "INI", value: 0 },
            { column: "ttc_paye", value: 0 },
            { column: "MONT_FACT", value: 0 },
            {
              column: "MVM_TYPE",
              operator: "IN",
              value:
                this.fact_type == 1
                  ? [1, 17]
                  : this.fact_type == 3
                  ? [3, 23]
                  : [this.fact_type],
            },
            {
              column: "DECISION_RET",
              value: this.fact_type == 2 || this.fact_type == 4 ? 2 : null,
            },
          ],
        },
      };
      let r = await this.requette(ACTUAL_MVMS, v, "no-cache");
      if (r) this.mvms_list = r.allmvm;
      r = await this.requette(EXERCICE_PERIODE, "", "no-cache");
      if (r) {
        this.exercicesperiodes = r.exercicesperiodes;
      }
      r = await this.requette(LAST_FACTURE, "", "no-cache");
      if (r) {
        let l = r.getlastfacture.filter(
          (elm) => elm.fact_type == this.fact_type
        );
        if (l.length > 0) this.last_facture = l[0];
      }
    },
    async get_data() {
      this.loading = true;
      await this.refresh();

      let r = await this.requette(DATA);
      if (r) {
        this.modes = r.modes;
        this.modalites = r.modalites;
        this.comptes = r.comptes.filter(
          (elm) => elm.struct_id == this.$store.state.me.struct_id
        );
      }

      this.loading = false;
    },

    async FactChange(item) {
      if (item) {
        if (item.id) {
          this.data_load = true;
          this.selitem = item;
          let r = await this.requette(
            FACT,
            {
              where: { column: "ID", value: item.id },
            },
            "no-cache"
          );
          if (r) {
            this.facture = r.facts.data[0];
            this.kf++;
            this.data_load = false;
          }
        } else {
          this.mvme = {};
          this.selitem = item;
          this.keymvm++;
        }
      }
    },
    NewFactForm(item) {
      this.edition = true;
      this.$store.dispatch("Editing", true);
      this.facture = item;
      this.kf++;
    },
  },
};
</script>
